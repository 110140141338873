import React from "react";
import styled from "styled-components";

// components
import Banner from "./banner";

// styles
// import "./header.css";
const HeaderWrapper = styled.div`
  .header-wrapper {
    overflow: hidden;
  }
`;

function Header(props) {
  return (
    <HeaderWrapper>
      <header className="header-wrapper">
        <Banner />
      </header>
    </HeaderWrapper>
  );
}

export default Header;
