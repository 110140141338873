// components
import Home from "./components/routes/home";
import About from "./components/routes/about";
import Services from "./components/routes/services";
import PriceList from "./components/routes/pricelist";
import Footer from "./components/footer";
import Header from "./components/header";
import Navbar from "./components/navbar";

// styles
import "./App.css";

function App() {
  return (
    <div className="App">
      <main>
        <Header />
        <Navbar />
        <Home />
        <Services />
        <About />
        <PriceList />
      </main>
      <Footer />
    </div>
  );
}

export default App;
