import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneSquareAlt } from "@fortawesome/free-solid-svg-icons";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

// styles
import "./contact-info.css";

function ContactInfo(props) {
  return (
    <div className="contact-container">
      <h3>Kontakt</h3>
      <div className="contact-info-wrapper ">
        <div className="contact-name">
          <p className="bold">MVDr. Michaela Kučerová</p>
          <p>KVL 6912</p>
          <p>
            <span className="bold">IČ:</span> 06272690
          </p>
          <p>
            <span className="bold">č. účtu:</span> 1515407066/3030
          </p>
        </div>

        <div className="contact-phone-mail-fb">
          <div className="contact-phone">
            <FontAwesomeIcon
              icon={faPhoneSquareAlt}
              className="icon"
            ></FontAwesomeIcon>
            <a href="tel:+420734804430">734 804 430</a>
          </div>
          <div className="contact-mail">
            <FontAwesomeIcon
              icon={faEnvelopeSquare}
              className="icon"
            ></FontAwesomeIcon>
            <a href="mailto:kucerova@domaciveterinarka.cz">
              kucerova@domaciveterinarka.cz
            </a>
          </div>
          <div className="contact-fb">
            <FontAwesomeIcon
              icon={faFacebook}
              className="icon"
            ></FontAwesomeIcon>
            <a href="https://facebook.com/domaciveterinarka">
              domaciveterinarka
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactInfo;
