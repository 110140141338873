import React from "react";
import styled from "styled-components";
import ImgMap from "../../img/map.jpg";

const HomeRouteWrapper = styled.div`
  .home-route-wrapper {
    padding: 4rem 7.5% 1rem 7.5%;
    text-align: center;
    letter-spacing: 0.1rem;
  }

  .home-section {
    display: grid;
    grid-gap: 5%;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;

    h2 {
      font-size: 2vw;
      font-weight: bold;
      color: #79b1bb;
      text-transform: uppercase;
    }
  }

  .section-box01 {
    img {
      max-width: 100%;
    }
  }

  .section-box02 {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      color: #404646;
      font-size: 2vw;
    }

    .title-box {
      padding: 1rem 0;
      margin-bottom: 2rem;
      border-top: solid 2px #79b1bb;
      border-bottom: solid 2px #79b1bb;

      .subtitle {
        color: #79b1bb;
        text-transform: uppercase;
        font-size: 1.8vw;
      }

      .subtitle2 {
        color: #79b1bb;
        text-transform: uppercase;
        font-size: 1.4vw;
        border-top: solid 1px #79b1bb;
        padding-top: 1rem;
        margin-top: 1rem;
      }
    }

    .subtitle-name {
      margin-bottom: 2rem;
    }

    .phone-box {
      width: 50%;
      background-color: #a0a3a3;
      border-radius: 1rem;
      padding: 1rem 0;

      a:link {
        font-size: 2vw;
        font-weight: bold;
      }

      a:hover {
        color: black;
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .phone-box {
      width: 75%;
      padding: 0.5rem 0;
    }
  }

  @media screen and (max-width: 770px) {
    .home-route-wrapper {
      padding-top: 3rem;
    }

    .home-section {
      display: flex;
      flex-direction: column;

      h2 {
        // font-size: 4vw;
        font-size: 3vmax;
      }

      .subtitle {
        // font-size: 3vw !important;
        font-size: 2vmax !important;
      }

      .subtitle2 {
        // font-size: 3vw !important;
        font-size: 1.8vmax !important;
      }

      .subtitle-name {
        // font-size: 3.5vw;
        font-size: 2.5vmax;
        margin-bottom: 2rem;
      }

      .title-box {
        margin-bottom: 2rem;
      }

    .section-box01 {
      order: 2;
      margin: 3rem 0;
    }

    .section-box02 {

    }

    a:link {
      font-size: 2vmax !important;
    }
  }

  @media screen and (max-width: 450px) {

    .title-box {
      margin-bottom: 1rem  !important;
    }

    .section-box01 {
      margin: 2rem 0 !important;
    }

  }
`;

// styles
// import "../../App.css";

function Home(props) {
  return (
    <>
      <HomeRouteWrapper>
        <section className="home-route-wrapper" id="home">
          <div className="home-section section01">
            <div className="section-box01">
              <img
                src={ImgMap}
                alt="Přijedeme k Vám domů, Dobřichovice a okolí. Řevnice, Černošice, Radotín, Zbraslav, Mníšek pod Brdy, Beroun, Loděnice, Rudná, Karlík, Vonoklasy, Mořina, Mořinka, Roblín,  Karlštejn, Hlásná Třebáň, Zadní Třebáň, Všenory, Srbsko, Tetín, Bubovice, Měňany, Liteň, Svinaře, Skuhrov, Řitka, Klínec, Líšnice, Čisovice, Hvozdnice, Davle, Vrané nad Vltavou, Vysoký Újezd Mezouň, Nučice, Tachlovice, Choteč, Třebotov, Kosoř, Lochkov, Lety, Halouny, Jíloviště, Černolice, Trnová, Korno, Dolní Vlence, Běleč, Lhotka, Nesvačily, Všeradice, Bykoš, Suchomasty, Hodyně, Hatě, Drahlovice, Podbrdy, Vižina, Vinařice, Tobolka, Koda, Kozolupy, Hostim, Svatý Jan Pod Skalou, Vráž, Sedlec, Trněný újezd, Kuchař, Kuchařík, Chýnice, Ořech, Zadní Kopanina, Zmrzlík, Zbuzany, Dobříč, Lipence"
              />
            </div>
            <div className="section-box02">
              <div className="title-box">
                <h2 className="title">Veterinární péče o psy a kočky</h2>
                <h3 className="subtitle">v pohodlí vašeho domova</h3>
                <div className="subtitle2">
                  <h4>Dobřichovice a okolí</h4>
                </div>
              </div>

              <h3 className="subtitle-name">MVDr. Michaela Kučerová</h3>
              <div className="phone-box">
                <p>Objednání na</p>
                <a href="tel:+420734804430">734 804 430</a>
              </div>
            </div>
          </div>
        </section>
      </HomeRouteWrapper>
    </>
  );
}

export default Home;
