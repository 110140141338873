import React from "react";
import styled from "styled-components";
import ImgProfile from "../../img/about-min.jpg";

// constants
const AboutRouteWrapper = styled.div`
  .about-route-wrapper {
    padding: 4rem 7.5%;
    text-align: center;
    letter-spacing: 0.1rem;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5%;
  }

  .section-header {
    grid-row: 1/2;
    grid-column: 2/3;
    z-index: 5;
    text-align: right;
    margin-bottom: 25px;

    h2 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1rem;
      color: #bcd8dd;
      padding: 0.5% 12% 0.5% 3%;
      background-color: #666b6b;
      display: inline;
      position: relative;
      left: 12%;
      top: 5%;
      z-index: 5;
    }
  }

  .section-box01 {
    grid-column: 1/3;
    grid-row: 1/2;
    text-align: left;
    padding-top: 3%;

    .section-content {
      color: black;

      h3 {
        color: black;
        font-size: 1.2rem;
        padding: 0 0 0.3rem 0;
      }

      ul {
        list-style-type: none;
        list-style-position: inside;
        margin-bottom: 1.5rem;
      }

      .intro {
        margin-bottom: 1.5rem;
        margin-top: 0.5rem;
      }
    }
  }

  .section-box02 {
    grid-column: 3/4;
    grid-row: 1/2;

    img {
      object-fit: contain;
      max-width: 100%;
      border-radius: 5%;
      border: solid 1px #c6c8c8;
    }
  }

  @media screen and (max-width: 770px) {
    .about-route-wrapper {
      grid-template-rows: auto auto auto;
      grid-template-columns: 1fr;
      grid-gap: 1%;
      min-height: auto;
      padding-bottom: 2rem;
      padding-top: 3rem;
      line-height: 1.7;
    }

    .section-box01 {
      grid-row: 3/4;
      grid-column: 1/2;
      padding-top: 0;
    }

    .section-box02 {
      grid-row: 2/3;
      grid-column: 1/2;
      margin: auto;

      img {
        display: inline-block;
        width: 60%;
        margin: 0;
      }
    }

    .section-header {
      grid-row: 1/2;
      grid-column: 1/2;
      text-align: center;

      h2 {
        left: 0%;
        top: 0%;
        padding: 0.5% 15%;
      }
    }

    li {
      margin-bottom: 1rem;
    }
  }
`;

function About(props) {
  return (
    <AboutRouteWrapper>
      <section className="about-route-wrapper" id="about">
        <div className="section-box01">
          <div className="section-header">
            <h2>O mně</h2>
          </div>
          <div className="section-content">
            <p className="intro">
              Veterina mě baví a jsem ráda, když je vztah chovatel - mazlíček -
              veterinář pohodový, osobní a plný důvěry. <br />
              Proto jezdím k pejskům a kočičkám na návštěvy domů a šetřím nervy
              jim i jejich páníčkům.
            </p>
            <h3>Vzdělání a praxe</h3>
            <ul>
              <li>
                Čtyřletá pracovní zkušenost jako lékařka na zavedených
                veterinárních klinikách
              </li>
              <li>Fakulta veterinárního lékařství, VFU Brno, 2017</li>
              <li>
                Zahraniční stáže na klinikách v Německu, Rakousku a Anglii
                (2013-2016)
              </li>
            </ul>
            <h3>Oftalmologie</h3>
            <ul>
              <li>
                Má vize je poskytovat oční vyšetření a zákroky na špičkové
                úrovni.
              </li>
              <li>
                2023 - Vet Coaching kurz Oftalmologie v praxi malých zvířat
                (Praha, Žilina)
              </li>
              <li>2023 - VetClassess Onkologická kazuistika v oftalmologii</li>
              <li>2023 - VetFair Základy chirurgie spojivky a rohovky</li>
              <li>
                2021 - VetFair Praktické tipy v hojení hlubokých rohovkových
                vředů
              </li>
              <li>
                2018-2019 - asistence oftalmologie MVDr. Tereza Štěpánek
                Zavadilová při internshipu na Veterinární klinice Jaggy
              </li>
            </ul>

            <p>V současnosti na rodičovské dovolené.</p>
          </div>
        </div>
        <div className="section-box02">
          <img src={ImgProfile} alt="Michaela Kučerová - domácí veterinářka" />
        </div>
      </section>
    </AboutRouteWrapper>
  );
}

export default About;
