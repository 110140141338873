import React from "react";
import styled from "styled-components";
import imgDog from "../img/01_dog-min.jpg";
import imgCat from "../img/02_cat-min.jpg";
import imgDogSono from "../img/03_dog_sono-min.jpg";
import imgCatNails from "../img/04_cat_nails-min.jpg";
import ImgLogo from "../img/logo.jpg";
import { useLocation } from "react-router-dom";

// styles
// import "./banner.css";

const BannerWrapper = styled.div`
  .banner-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(40, 1fr);

    h1 {
      display: none;
    }
  }

  .banner-text {
    grid-column: 18/41;
    grid-row: 1/2;
    transform: skew(10deg);
    /* border-left: solid 1px #000000; */
    // background-color: #79b1bb;
    background-color: white;
  }

  .banner-content {
    grid-column: 20/41;
    grid-row: 1/2;
    z-index: 5;
    // background-color: #79b1bb;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .banner-img {
    grid-column: 1/20;
    grid-row: 1/2;
    background-image: url(${imgDog});
    background-size: cover;
    background-repeat: no-repeat;
  }

  .about {
    background-image: url(${imgCat});
    background-position: 0 20%;
  }

  .sluzby {
    background-image: url(${imgDogSono});
    background-position: 0% 35%;
  }

  .cenik {
    background-image: url(${imgCatNails});
    background-position: 0% 70%;
  }

  .banner-content-logo {
    width: 100%;
  }
`;

function Banner(props) {
  // hooks
  const { pathname } = useLocation();
  // hooks

  // private
  function getClassFromPathname(pathname) {
    let className = pathname.slice(1);
    return className;
  }
  // private

  // render
  return (
    <BannerWrapper>
      <div className="banner-wrapper">
        <div className="banner-text" />
        <div className="banner-content">
          <img
            src={ImgLogo}
            className="banner-content-logo"
            alt="Domácí veterinářka"
          />
          <h1>Domácí veterinářka</h1>
        </div>
        <div className={`banner-img ${getClassFromPathname(pathname)}`} />
      </div>
    </BannerWrapper>
  );
  // render
}

export default Banner;
