import React from "react";
import styled from "styled-components";
import ImgCat from "../../img/02_cat-min.jpg";
import ImgDogSono from "../../img/03_dog_sono-min.jpg";
import ImgCatNails2 from "../../img/06_cat_nails_2_min.jpg";

// constants
const ServicesRouteWrapper = styled.div`
  .services-route-wrapper {
    padding: 4rem 7.5%;
    text-align: center;
    letter-spacing: 0.1rem;
    background-color: #79b1bb;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5%;
    height: auto;
  }

  .section-header {
    grid-row: 1/2;
    grid-column: 2/3;
    z-index: 5;
    text-align: left;
    margin-bottom: 25px;

    h2 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1rem;
      color: #bcd8dd;
      padding: 0.5% 3% 0.5% 12%;
      background-color: #666b6b;
      display: inline;
      position: relative;
      left: -12%;
      top: 5%;
      z-index: 5;
    }
  }

  .section-box01 {
    grid-column: 2/4;
    grid-row: 1/2;
    text-align: left;
    padding-top: 3%;

    .section-content {
      color: white;

      h3 {
        color: white;
        font-size: 1.2rem;
        padding: 0 0 0.5rem 0;
      }

      ul {
        list-style-type: none;
        list-style-position: inside;
        margin-bottom: 1.5rem;
      }

      li {
        padding-bottom: 0.5rem;
      }

      .section-desc {
        margin-bottom: 1.5rem
      }
    }
  }

  .section-box02 {
    grid-column: 1/2;
    grid-row: 1/2;

    img {
      object-fit: contain;
      max-width: 100%;
      border-radius: 5%;
      border: solid 1px #6d9fa8;
      margin-bottom: 2%;
    }

    img:last-child {
      margin-bottom: 0;
    }

    img:last-child {
      display: none;
    }
  }

  c
    .section-box02 {
      img:last-child {
        display: block;
      }
    }
  }

  @media screen and (max-width: 920px) {
      .section-box02 {
        img:last-child {
          display: block;
        }
      }
    }

  @media screen and (max-width: 770px) {
    .services-route-wrapper {
      grid-template-rows: repeat(1fr, 3);
      grid-template-columns: 1fr;
      grid-gap: 1%;
      min-height: auto;
      padding-bottom: 2rem;
      padding-top: 3rem;
      line-height: 1.7;
    }

    .section-box01 {
      grid-row: 3/4;
      grid-column: 1/2;
      padding-top: 0;
    }

    .section-box02 {
      grid-row: 2/3;
      grid-column: 1/2;
      margin: auto;

      img {
        display: none;
      }

      img:last-child {
        display: none;
      }

      img:first-child {
        display: inline-block;
        width: 60%;
        margin: 0;
      }
    }

    .section-header {
      grid-row: 1/2;
      grid-column: 1/2;
      text-align: center;

      h2 {
        left: 0%;
        top: 0%;
        padding: 0.5% 15%;
      }
    }
  }
`;

function Services(props) {
  return (
    <ServicesRouteWrapper>
      <section className="services-route-wrapper" id="services">
        <div className="section-box01">
          <div className="section-header">
            <h2>Služby</h2>
          </div>
          <div className="section-content">
            <p className="section-desc">
              Ošetření u vás doma ušetří nervy a čas vám i vašim zvířecím
              miláčkům. Už se nemusíte stresovat s převozem do ordinace a zpět.
              Nadstandardní přístrojové vybavení, včetně mobilního ultrazvuku.
            </p>
            <h3>Oftalmologie</h3>
            <ul>
              <li>
                oftalmologické vyšetření včetně měření nitroočního tlaku,
                vyšetření očního pozadí, sonografie oka, oftalmologické
                intervence
              </li>
            </ul>
            <h3>Preventivní péče</h3>
            <ul>
              <li>
                vakcinace, čipování, vystavení europasu, odčervení, ošetření
                drápků
              </li>
            </ul>
            <h3>Diagnostika a konzultace</h3>
            <ul>
              <li>
                laboratorní a mikroskopické vyšetření, ultrazvuk, otoskopie
              </li>
            </ul>
            <h3>Léčba</h3>
            <ul>
              <li>medikace onemocnění, infuzní terapie, ošetření ran</li>
            </ul>
            <h3>Eutanázie</h3>
            <ul>
              <li>citlivé uspání pejska, nebo kočičky doma</li>
            </ul>
            <br />
            <p>
              Ošetření vyžadující anestezii ve spolupráci s dalšími pracovišti.
            </p>
          </div>
        </div>
        <div className="section-box02">
          <img src={ImgCat} alt="poslech kočky stetoskopem" />
          <img src={ImgDogSono} alt="sono vyšetření psa" />
          <img src={ImgCatNails2} alt="stříhání kočičích drápků" />
        </div>
      </section>
    </ServicesRouteWrapper>
  );
}

export default Services;
