import React from "react";

// components
import ContactInfo from "./contact-info";

// styles
import "./footer.css";

function Footer(props) {
  return (
    <footer className="footer-wrapper" id="contact">
      <ContactInfo />
    </footer>
  );
}

export default Footer;
