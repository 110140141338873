import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { Link } from "react-router-dom";

// styles
//import "./navbar.css";

const Navigation = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;

  .navbar-wrapper {
    background-color: #404646;
    padding: 0.5rem 0;
    text-align: center;
  }

  .navbar-list {
    padding: 0 2rem;
    display: flex;
    justify-content: space-evenly;
  }

  .navbar-list a {
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.3rem;
    font-size: 1.1rem;
  }

  .icon-bars-wrapper {
    color: white;
    font-size: 1.2rem;
    display: none;

    > svg {
      position: relative;
      top: 8px;
    }

    > p {
      padding-left: 0.6rem;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 0.3rem;
      font-size: 1.1rem;
    }
  }

  @media screen and (max-width: 770px) {
    .navbar-list {
      flex-direction: column;
      align-items: center;
      display: none;
    }

    .navbar-list a {
      font-weight: normal;
      font-size: 1rem;
    }

    .expanded {
      display: flex;
    }

    .icon-bars-wrapper {
      display: inline-block;
      display: flex;
      justify-content: center;
    }
  }
`;

function Navbar(props) {
  // hooks
  const [navToggled, setNavToggled] = useState(false);
  console.log(navToggled);
  // hooks

  // private
  function handleNavToggle() {
    setNavToggled(!navToggled);
  }

  // private

  // render
  return (
    <Navigation>
      <nav className="navbar-wrapper">
        <div className="icon-bars-wrapper">
          <FontAwesomeIcon
            icon={faBars}
            className="icon-bars"
            onClick={handleNavToggle}
          ></FontAwesomeIcon>
          <p>Menu</p>
        </div>
        <ul className={`navbar-list ${navToggled ? "expanded" : ""}`}>
          <li>
            <a href="#home" onClick={handleNavToggle}>
              Home
            </a>
          </li>
          <li>
            <a href="#services" onClick={handleNavToggle}>
              Služby
            </a>
          </li>
          <li>
            <a href="#about" onClick={handleNavToggle}>
              O mně
            </a>
          </li>
          <li>
            <a href="#pricelist" onClick={handleNavToggle}>
              Ceník
            </a>
          </li>
          <li>
            <a href="#contact" onClick={handleNavToggle}>
              Kontakt
            </a>
          </li>
        </ul>
      </nav>
    </Navigation>

    // <Navigation>
    //   <nav className="navbar-wrapper">
    //     <div className="icon-bars-wrapper">
    //       <FontAwesomeIcon
    //         icon={faBars}
    //         className="icon-bars"
    //         onClick={handleNavToggle}
    //       ></FontAwesomeIcon>
    //       <p>Menu</p>
    //     </div>
    //     <ul className={`navbar-list ${navToggled ? "expanded" : ""}`}>
    //       <li>
    //         <Link to="/">Home</Link>
    //       </li>
    //       <li>
    //         <Link to="/about">O mně</Link>
    //       </li>
    //       <li>
    //         <Link to="/sluzby">Služby</Link>
    //       </li>
    //       <li>
    //         <Link to="/cenik">Ceník</Link>
    //       </li>
    //       <li>
    //         <a href="#">Kontakt</a>
    //       </li>
    //     </ul>
    //   </nav>
    // </Navigation>
  );
  // render
}

export default Navbar;
