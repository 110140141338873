import React from "react";
import styled from "styled-components";
import ImgCatNails from "../../img/04_cat_nails-min.jpg";
import ImgCatStetoscope from "../../img/05-cat-stetoscope-min.jpg";

// constants
const PricelistRouteWrapper = styled.div`
  .pricelist-route-wrapper {
    padding: 4rem 7.5%;
    text-align: center;
    letter-spacing: 0.1rem;
    background-color: #79b1bb;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5%;
  }

  strong {
    font-weight: bold;
  }

  .section-header {
    grid-row: 1/2;
    grid-column: 2/3;
    z-index: 5;
    text-align: left;
    margin-bottom: 25px;

    h2 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1rem;
      color: #bcd8dd;
      padding: 0.5% 3% 0.5% 12%;
      background-color: #666b6b;
      display: inline;
      position: relative;
      left: -12%;
      top: 5%;
      z-index: 5;
    }
  }

  .section-box01 {
    grid-column: 2/4;
    grid-row: 1/2;
    text-align: left;
    padding-top: 3%;

    .section-content {
      color: white;

      h3 {
        color: white;
        font-size: 1.2rem;
        padding: 0 0 0.5rem 0;
      }

      ul {
        list-style-type: none;
        list-style-position: inside;
        margin-bottom: 1.5rem;
      }
    }
  }

  .section-box02 {
    grid-column: 1/2;
    grid-row: 1/2;

    img {
      object-fit: contain;
      max-width: 100%;
      border-radius: 5%;
      border: solid 1px #6d9fa8;
      margin-bottom: 2%;
    }

    img:last-child {
      margin-bottom: 0;
    }

    // img:last-child {
    //   display: none;
    // }
  }

  // @media screen and (max-width: 920px) {
  //   .section-box02 {
  //     img:last-child {
  //       display: block;
  //     }
  //   }
  // }

  @media screen and (max-width: 770px) {
    .pricelist-route-wrapper {
      grid-template-rows: auto auto auto;
      grid-template-columns: 1fr;
      grid-gap: 1%;
      min-height: auto;
      padding-bottom: 2rem;
      padding-top: 3rem;
      line-height: 1.9;
    }

    .section-box01 {
      grid-row: 3/4;
      grid-column: 1/2;
      padding-top: 0;
    }

    .section-box02 {
      grid-row: 2/3;
      grid-column: 1/2;
      margin: auto;

      img {
        display: none;
      }

      img:last-child {
        display: none;
      }

      img:first-child {
        display: inline-block;
        width: 60%;
        margin: 0;
      }
    }

    .section-header {
      grid-row: 1/2;
      grid-column: 1/2;
      text-align: center;

      h2 {
        left: 0%;
        top: 0%;
        padding: 0.5% 15%;
      }
    }
  }
`;

function PriceList(props) {
  return (
    <PricelistRouteWrapper>
      <section className="pricelist-route-wrapper" id="pricelist">
        <div className="section-box01">
          <div className="section-header">
            <h2>Ceník</h2>
          </div>
          <div className="section-content">
            <ul>
              <li>
                Návštěva domácnosti - <strong>300,-</strong>
              </li>
              <li>
                Cestovné - <strong>20,-/km</strong>
              </li>
              <li>
                Vakcinace - <strong>450-900,-</strong>
              </li>
              <li>
                Klinické vyšetření - od <strong>500,-</strong>
              </li>
              <li>
                Oftalmologické vyšetření - <strong>450-900,-</strong>
              </li>
              <li>
                Sonografické vyšetření (ultrazvuk) - <strong>200-1000,-</strong>
              </li>
              <li>
                Eutanázie - <strong>1500-3000,-</strong>
              </li>
              <li>
                Pohotovost - <strong>500-1000,-</strong>
              </li>
              <li>
                <p>Ceny ostatních úkonů na vyžádání ráda sdělím.</p>
              </li>
            </ul>
            <p>Ceny vyšetření dle časové náročnosti a použitého materiálu.</p>
          </div>
        </div>
        <div className="section-box02">
          <img src={ImgCatNails} alt="stříhání kočičích drápků" />
          <img src={ImgCatStetoscope} alt="kočka se stetoskopem" />
          {/* <img src={ImgCatStetoscope} /> */}
        </div>
      </section>
    </PricelistRouteWrapper>
  );
}

export default PriceList;
